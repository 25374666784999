<template>
  <div class="add-form">
    <a-steps v-model="stepActive" type="navigation" size="small" :style="stepStyle">
      <a-step title="Step 1" :description="$t('addClusterStep.1')" disabled />
      <a-step title="Step 2" :description="$t('addClusterStep.2')" disabled />
      <a-step title="Step 3" :description="$t('addClusterStep.3')" disabled />
      <a-step title="Step 4" :description="$t('addClusterStep.4')" disabled />
    </a-steps>

    <div v-if="stepActive == 0" class="provider-item-container">
      <div v-for="item in providerList" :key="item" @click="selectProvider(item)">
        <div v-if="item == 'ProviderAWS'">
          <div class="provider-item-title">Provider AWS</div>
          <img :src="aws" />
        </div>
        <div v-if="item == 'ProviderALiCloud'">
          <div class="provider-item-title">Provider ALiCloud</div>
          <img :src="aliyun" />
        </div>
        <div v-if="item == 'ProviderGCP'">
          <div class="provider-item-title">Provider GCP</div>
          <img :src="gcp" />
        </div>
        <div v-if="item == 'ProviderKubeconfig'">
          <div class="provider-item-title">Provider KubeConfig</div>
          <img :src="onpremise" />
        </div>
        <div v-if="item == 'ProviderTencent'">
          <div class="provider-item-title">Provider Tencent</div>
          <img :src="tencentcloud" />
        </div>

        <div v-if="item == 'ProviderHuawei'">
          <div class="provider-item-title">Provider Huawei</div>
          <img :src="huaweicloud" />
        </div>
      </div>
    </div>

    <div v-if="stepActive == 1">
      <cluster-form :form="form" type="add" ref="clusterForm">
        <template #button>
          <el-button @click="toMoreConf" type="primary" plain size="small">
            {{ $t("handle.nextStep") }}
          </el-button>

          <el-button @click="toInit" type="primary" plain size="small">
            {{ $t("handle.lastStep") }}
          </el-button>
        </template>
      </cluster-form>
    </div>

    <div v-if="stepActive == 2">
      <el-card style="margin-bottom: 20px;" shadow="never">
        <div class="more-conf-item">
          <div>
            <div class="title">
              {{ $t("clusterMonitorConf") }}
            </div>
            <div class="desc">{{ $t("clusterMonitorConfDescription") }}</div>
          </div>

          <el-switch v-model="addMonitorForm"></el-switch>
        </div>

        <monitor-form v-if="addMonitorForm" ref="monitorForm" />
      </el-card>

      <div>
        <el-button @click="submit" type="primary" :loading="addProviderLoading" plain size="small">
          {{ $t("handle.submit") }}
        </el-button>
        <el-button @click="stepActive--" type="primary" plain size="small">
          {{ $t("handle.lastStep") }}
        </el-button>
      </div>
    </div>

    <div v-if="stepActive == 3">
      <div class="form-result-container">
        <i class="el-icon-circle-check"></i>
        <div class="form-result-header">{{ $t("success") }}</div>
        <div class="form-result-tips">
          {{ $t("createClusterSuccessTips") }}
          <br />
          <a href="javascript:;" @click="$router.push('/admin/user')"> {{ $t("userList") }} </a>/
          <a href="javascript:;" @click="$router.push('/admin/role')"> {{ $t("roleList") }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import provider from "@/mixins/provider";
import ClusterForm from "./Form.vue";
import MonitorForm from "./MonitorForm.vue";
import { providerList, addProvider, addMonitors } from "api/app";

export default {
  components: {
    ClusterForm,
    MonitorForm
  },

  mixins: [provider],
  data() {
    return {
      providerList: [],
      stepActive: 0,
      addProviderLoading: false,
      form: {},
      addMonitorForm: false,
      addAlarmForm: false,
      monitorRepSuccess: false,
      monitorErrMessgae: "",
      stepStyle: {
        marginBottom: "20px",
        boxShadow: "0px -1px 0 0 #e8e8e8 inset"
      }
    };
  },

  methods: {
    getProviderList() {
      providerList().then(response => {
        if (response.code === 0) {
          for (let key in response.data) {
            if (response.data[key]) this.providerList.push(key);
          }
        }
      });
    },

    selectProvider(value) {
      this.form.kind = value;
      this.stepActive++;
    },

    toInit() {
      this.stepActive--;
      this.form = {
        name: "",
        kind: "",
        organizationUUID: this.organization,
        regionID: "",
        accessKey: "",
        secretAccessKey: "",
        kubeconfig: "",
        zoneID: "",
        cluster: "",
        gcpConfJson: "",
        status: "",
        account: "",
        accountPassword: "",
        domainName: "",
        projectID: "",
        projectName: ""
      };
    },

    toMoreConf() {
      this.$refs["clusterForm"].$refs["form"].validate(valid => {
        if (valid) {
          this.stepActive++;
        }
      });
    },

    async submit() {
      let valid = true;

      if (this.addMonitorForm) {
        this.$refs["monitorForm"].$refs["form"].validate(monitorValid => {
          monitorValid ? (valid = true) : (valid = false);
        });
      }

      if (valid) {
        this.addProviderLoading = true;
        this.form.organizationUUID = this.organization;
        let response = await addProvider(this.form);
        this.addProviderLoading = false;

        if (response.code === 0) {
          if (this.addMonitorForm) {
            this.monitorRepSuccess = false;

            let monitorForm = this.$refs["monitorForm"].monitorForm;
            monitorForm.providerUUID = response.data.uuid;
            monitorForm.organizationUUID = this.organization;

            let monitorResponse = await addMonitors(monitorForm);

            monitorResponse.code === 0
              ? (this.monitorRepSuccess = true)
              : (this.monitorErrMessgae = monitorResponse.message);

            this.stepActive++;
          } else {
            this.stepActive++;
          }
        }
      }
    }
  },

  mounted() {
    this.getProviderList();
  },

  computed: {
    organization() {
      return this.$store.state.app.organization;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";

.form-result-container {
  text-align: center;

  .el-icon-circle-check {
    color: $color-success;
    font-size: 60px;
    margin-bottom: 20px;
  }

  .form-result-header {
    font-size: 20px;
    line-height: 40px;
  }

  .form-result-tips {
    font-size: 14px;
    color: $color-sub;
    line-height: 28px;
    margin-bottom: 10px;
  }
}

.provider-item-container {
  @include flex();

  & > div {
    width: 30%;
    text-align: center;
    background-color: #f9fafb;
    margin-bottom: 20px;
    height: 160px;
    position: relative;
    cursor: pointer;
    border-radius: 8px;

    &:hover {
      box-shadow: $common-shadow;
      background-color: #fff;
      .registry-item-title {
        background-color: #b1c9e2;
      }
    }
  }

  .provider-item-title {
    line-height: 30px;
    background-color: #adbfd5;
    color: #fff;
    border-radius: 8px 8px 0 0;
    transition: 0.5s all ease;
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 140px;
  }
}
</style>
