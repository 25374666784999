<template>
  <div>
    <el-form :model="monitorForm" label-position="top" size="small" ref="form">
      <div class="list-title">
        <div class="title">
          基础信息设置
        </div>
      </div>

      <el-form-item
        :label="$t('monitorHost')"
        prop="host"
        :rules="{ required: true, message: $t('rules.require'), trigger: 'blur' }"
      >
        <el-input v-model="monitorForm.host" placeholder="http://localhost:9090">
          <el-select v-model="monitorForm.kind" slot="prepend" style="width: 120px;">
            <el-option value="Prometheus" label="Prometheus"> </el-option>
          </el-select>
        </el-input>
      </el-form-item>

      <el-form-item label="Pod 别名">
        <el-input v-model="monitorForm.podAlias" placeholder="pod or pod_name"></el-input>
        <span class="help">
          用于监控数据过滤，多个 prometheus的版本采集的数据对元数据的处理不一样，存在 pod / pod_name 两种情况，
          避免数据查询失败，可根据实际情况填写，默认pod
        </span>
      </el-form-item>

      <el-form-item label="Node 别名">
        <el-input v-model="monitorForm.nodeAlias" placeholder="node or node_name"></el-input>
        <span class="help">
          用于监控数据过滤，多个prometheus的版本采集的数据对元数据的处理不一样，存在 node / node_name 两种情况，
          避免数据查询失败，可根据实际情况填写，默认node
        </span>
      </el-form-item>

      <el-form-item :label="$t('monitorReady')">
        <el-switch v-model="monitorForm.ready"></el-switch>
      </el-form-item>

      <div class="list-title">
        <div class="title">
          Thanos设置
        </div>

        <div class="desc">
          当多个集群的监控数据被聚合后，为了区分监控数据来自于具体的集群，需要明确标识监控数据中的集群身份，数据来自于
          Prometheus 的 external_labels 字段
        </div>
      </div>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="唯一性标示 Key">
            <el-input v-model="monitorForm.monitorKey" placeholder="cluster"></el-input>
            <span class="help">Thanos架构下，Prometheus的唯一性标示 Label</span>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="唯一性标示 Value">
            <el-input v-model="monitorForm.monitorValue" placeholder="dev"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      monitorForm: {
        kind: "Prometheus",
        host: "",
        ready: true,
        podAlias: "",
        nodeAlias: "",
        monitorKey: "",
        monitorValue: ""
      }
    };
  }
};
</script>

<style></style>
